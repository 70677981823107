const projects = [
  {
    type: 'front', 
    name: 'Gari Motor Insurance', 
    description: 'This is a Motor Insurance Digital Market, where you can purchase motor insurance from different insurance companies.', 
    src: 'gari.png', 
    repository: '', 
    site: 'https://joingari.com/home', 
    technologies: ['React', 'Flutter', 'C#'],
  },
  {
    type: 'front', 
    name: 'Patumba', 
    description: 'Patumba unit trust is a fund which enables individuals, corporations and institutions that have common investment objectives to pool their money.', 
    src: 'patumba.png', 
    repository: '', 
    site: 'https://hobbiton.co.zm', 
    technologies: ['PHP', 'Angular', 'NestJS', 'PHP'],
  },
  {
    type: 'front', 
    name: 'LuSE', 
    description: 'The Lusaka Stock Exchange is the principal stock exchange of Zambia.', 
    src: 'luse.png', 
    repository: 'https://github.com/thiagodanobrega/frontend-online-store', 
    site: 'https://frontend-online-store-thiagodanobrega.vercel.app/', 
    technologies: ['React', 'Java', 'Flutter', 'React' , 'TypeScript'],
  },
  {
    type: 'back', 
    name: 'Lipila Payment Gateway', 
    description: 'Lipila is a payment gateway that enables you to accept payments from your customers using their mobile money accounts.', 
    src: 'back.png', 
    repository: 'https://github.com/thiagodanobrega/mysql-one-for-all', 
    site: 'https://github.com/thiagodanobrega/mysql-one-for-all', 
    technologies: ['C#', 'PostgreSQL', 'NodeJS', 'React', 'Flutter'],
  },
  
];

export default projects;