import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import '../styles/About.css';
import IconCloud from '../components/IconCloud';

const About = () => {
  return (
    <body>
      <TitleBar />
      <section className="flex-container">
        <section className="main-container">
          <Header />
          <MenuMobile />
          <main className="main-wrapper about">
            <div className="about-container">
              <span className="span-tag html">{'<html>'}</span>
              <br/>
              <span className="span-tag body">{'<body>'}</span>
              <br/>
              <span className="span-tag content">{'<h1>'}</span>
                <h1 className="title-about">Hello!,</h1>
              <span className="span-tag content">{'</h1>'}</span>
              <br/>
              <span className="span-tag content">{'<p>'}</span>
                <p className="text-about">
                  My name is<span className="span-highlight"> Charles Malama</span> I am a self-motivated, goal oriented, rich in intellectual communication and energetic person. I am an out-going person and able to interact with different individuals at various levels and keen to work within a growth oriented and progressive organization.
                </p>
                <p className="text-about">
                I adapt quickly to new environments and technology, I wish to enhance my career and skills through effort and practice and be an asset to the organization by applying the best of my knowledge and expertise to the advancement of the organization, and achievement of organizational goals. I acquired valuable management, marketing and communication skills during my studies.
                </p>
                
              <span className="span-tag content">{'</p>'}</span>
              <br/>
              <span className="span-tag body">{'</body>'}</span>
              <br/>
              <span className="span-tag html">{'</html>'}</span>
            </div>
            <IconCloud />
          </main>
        </section>
        <SideBar />
      </section>
      <Footer />
    </body>
  )
}

export default About
