import { Route, Routes } from 'react-router-dom';
import './App.css';
import AppProvider from './context/AppProvider';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Projects from './pages/Projects';

function App() {
  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/projects" element={ <Projects /> } />
        <Route path="/contact" element={ <Contact /> } />
        {/* <Route path="/recommendation" element={ <Recommendation /> } /> */}
      </Routes>
    </AppProvider>
  );
}

export default App;
