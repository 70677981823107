import '../styles/TitleBar.css';

const TitleBar = () => {
  return (
    <section className="title-bar">
      <p>charles_malama_portfolio</p>
      <div className="title-btn">
        <span className="close"/>
        <span className="minimize"/>
        <span className="maximize"/>
      </div>
    </section>
  )
}

export default TitleBar