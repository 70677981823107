import "../styles/Projects.css"
import { FiExternalLink, FiGithub } from 'react-icons/fi'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MenuMobile from '../components/MenuMobile'
import SideBar from '../components/SideBar'
import TitleBar from '../components/TitleBar'
import projects from '../data/projects'

const Projects = () => {
  return (
    <body>
    <TitleBar />
    <section className="flex-container">
      <section className="main-container">
        <Header />
        <MenuMobile />
        <main className="main-wrapper projects">
          <h1 className="title-page-projects">My projects...</h1>
          
          <div className="projects-container">
            {projects.map(({type, name, src, repository, description, site, technologies }, index) => (
              <div key={index} className="card-project">
                <div className="img-container">
                  <img src={src} alt="Imagem do project" className="img-project"/>
                </div>
                <h2 className="title-project">{name}</h2>
                <p>{description}</p>
                <div className="technologies-container">
                  {technologies.map(tech => (
                    <span className="span-technologies">{tech}</span>
                  ))}
                </div>
                <div className="links-container">
                  <a href={repository} title="Github" target="_blank" rel="noreferrer"><FiGithub /></a>
                  <a href={site} title="Ver aplicação" target="_blank" rel="noreferrer"><FiExternalLink /></a>
                </div>
              </div>
            ))}
          </div>
        </main>
      </section>
      <SideBar />
    </section>
    <Footer />
  </body>
  )
}

export default Projects