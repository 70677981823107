import { v4 } from "uuid";
import {Cloud, renderSimpleIcon, fetchSimpleIcons, SimpleIcon} from 'react-icon-cloud'
import React from 'react';


const useIcons = (slugs: string[]) => {
    const [icons, setIcons] = React.useState<SimpleIcon[]>()
    React.useEffect(() => {fetchSimpleIcons({slugs}).then(({simpleIcons}) => setIcons(Object.values(simpleIcons)))}, [])
  
    if (icons) {
      return icons.map((icon) => renderSimpleIcon({
        icon,
        size: 45,
        aProps: {
          onClick: (e: any) => e.preventDefault()
        }
      }))
    }
  
    // return <a>Loading</a>
}

  const slugs = [
    'amazonaws',
    'android',
    'androidstudio',
    'antdesign',
    'typescript',
    'vercel',
    'visualstudiocode',
    "javascript",
    "react",
    "html5",
    "css3",
    "testinglibrary",
    "jest",
    "docker",
    "git",
    "github",
    "visualstudiocode",
    "redux",
    "mysql",
  ]

const Component = () => {

    const icons = useIcons(slugs)

    return (
        <div className="iconCloud-container">
            <Cloud>
                icons={icons}
                backgroundHexColor={"#fff"}
                fallbackHexColor={"#000"}
                key={v4()}
                reverse={true}
                clickToFront={500}
                depth={1}
                imageScale={2}
                initial={[0.1, -0.1]}
                outlineColour={"#0000"}
                tooltip={"native"}
                tooltipDelay={0}
                wheelZoom={false}
            </Cloud>
        </div>
    );
}

export default Component
