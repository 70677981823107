import { Link, useLocation } from "react-router-dom";

type Props = {
    name: string,
    icon: string,
    path: string
  };

const Header = ({name, icon, path} : Props) => {

    const { pathname } = useLocation();

  return (
    <Link
      type="button"
      to={path}
      className={`nav-bar ${ pathname === path && 'active' }`}
    >
      <img src={ icon } alt="icon" className="icon-nav"/>
      <p>{ name }</p>
    </Link>
  )
}

export default Header