import { BsLinkedin, BsGithub } from 'react-icons/bs';
import '../styles/Footer.css';
const Footer = () => {
  return (
    <footer className="footer-container">
    <div className="links-footer">
      <p>meet me at:</p>
      <a href="https://www.linkedin.com/in/charles-malama-254532142/" 
        target="_blank" 
        className="icon-container" 
        rel="noreferrer"
      >
        <BsLinkedin className="icon-footer"/>
      </a>
      <a href="https://github.com/CodeK1ng" 
        target="_blank" 
        className="icon-container" 
        rel="noreferrer"
      >
        <BsGithub className="icon-footer"/>
      </a>
    </div>
    
    <p className="text-footer">Powered by Charles Malama</p>
    {/* <select className="select-language">
      <option>🇧🇷 pt-BR</option>
      <option>🇺🇸 en-US</option>
    </select> */}
  </footer>
  )
}

export default Footer