import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import '../styles/Home.css';
import image from '../images/_CB15474-removebg.png';
import resume from '../files/CharlesMalamaResume.pdf';

import { FiDownload } from 'react-icons/fi';
import MenuMobile from "../components/MenuMobile";
import { useTypewriter } from 'react-simple-typewriter'

const Home = () => {
    const {text} = useTypewriter({
        words: ['Mobile Application Development', 'USSD Development', 'Web Developer', 'Payments Integrations!'],
        loop: true,
      })
      
  return (
    <body>
    <TitleBar />
    <section className="flex-container">
      <SideBar />
      <section className="main-container">
        <Header />
        <MenuMobile />
        <main className="main-wrapper home">
          <div className="home-content">
            <p><span className="span">{'<'}</span>hello 👋 I'm</p>
            <h1>Charles Malama <span className="span">{'/>'}</span> </h1>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
          
          <div style={{ height: 20}}>
            <span style={{ color: 'white', fontWeight: 'bold' }}>{text}</span>
          </div>
        </span>
            <a 
              href={resume}
              target="_blank"
              className="btn-download" rel="noreferrer"
            >
              Download CV
              <FiDownload />
            </a>
          </div>
          <img 
            src={image} alt="Charles Malama" 
            className="image-perfil"
          />
        </main>
      </section>
      {/* <SideBar /> */}
    </section>
    <Footer />
  </body>
  )
}

export default Home