import Footer from "../components/Footer";
import Header from "../components/Header";
import MenuMobile from '../components/MenuMobile'
import SideBar from "../components/SideBar";
import TitleBar from '../components/TitleBar'
import contacts from "../data/contacts";
import "../styles/Contact.css"

const Contact = () => {
  return (
    <body>
      <TitleBar />
      <section className="flex-container">
        <section className="main-container">
          <Header />
          <MenuMobile />
          <main className="main-wrapper contact">
            <div className="title-container">
              <h1 className="title-contact">Let's talk 🤙</h1>
              <h2 className="message-contact">"It is only when they go wrong that machines remind you how powerful they are." – Clive James</h2>
            </div>
            <div className="list-contact">
              <p className="class-name line">.contact<span className="element">{' {'}</span></p>
              {
                contacts.map(({social, href, text}) => (
                  <p className="key-name line" key={social}>{social}: 
                    <a href={href} target="_blank" rel="noreferrer">{text}</a>;
                  </p>
                ))
              }
              <p className="element line">{'}'}</p>
            </div>
          </main>
        </section>
        <SideBar />
      </section>
      <Footer />
    </body>
  )
}

export default Contact